import * as React from "react"

import Layout from "../components/layout"
import Contact from "../components/contact";
import Services from "../components/services";
import Skills from "../components/skills";
import MyButton, { ButtonType } from "../components/MyButton";
import Seo from "../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo title="Welcome" />
    {/* <Services /> */}

    {/* <Skills /> */}

    {/* <MyButton buttonType={ButtonType.Primary}>GO!</MyButton>
    <MyButton buttonType={ButtonType.Danger}>GO!</MyButton> */}

    {/* <Contact /> */}

  </Layout>
)

export default IndexPage